import { render, staticRenderFns } from "./DialogNotificationPlayer.vue?vue&type=template&id=369b4d10&scoped=true"
import script from "./DialogNotificationPlayer.js?vue&type=script&lang=js&external"
export * from "./DialogNotificationPlayer.js?vue&type=script&lang=js&external"
import style0 from "./DialogNotificationPlayer.vue?vue&type=style&index=0&id=369b4d10&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "369b4d10",
  null
  
)

export default component.exports