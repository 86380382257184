<template>
  <v-app-bar
    v-if="userAuthenticated"
    app
    clipped-right
    elevate-on-scroll
    short
    :dense="!$vuetify.breakpoint.mdAndUp"
    style="border-bottom: 1px solid #0f101424 !important"
  >
    <v-app-bar-nav-icon @click="toggleSidebar()" />
    <v-toolbar-title
      v-if="$route && $route.name"
      class="subtitle-1"
    >
      {{ $route.name }}{{ getRouteParamId() }}
    </v-toolbar-title>
    <v-spacer />

    <v-toolbar-items>
      <v-divider
        inset
        vertical
        class="d-none d-md-flex"
      />

      <v-tooltip
        class="mx-4"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            icon
            class="d-none d-md-flex"
            v-on="on"
            @click="toggleDarkMode()"
          >
            <v-icon class="mr-1">
              {{
                $vuetify.theme.dark
                  ? "mdi-white-balance-sunny"
                  : "mdi-moon-waxing-crescent"
              }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{
          $vuetify.theme.dark ? "Modo claro" : "Modo escuro"
        }}</span>
      </v-tooltip>

      <!-- <template v-if="userGroup">
        <v-divider
          inset
          vertical
          class="d-none d-md-flex"
        />
        <v-btn
          v-if="userGroup && userGroup['name']"
          text
          small
          class="d-none d-md-flex"
        >
          {{ userGroup["name"] }}
        </v-btn>
      </template> -->

      <!-- <v-divider
        inset
        vertical
        class="d-none d-md-flex"
      /> -->

      <template v-if="userIsPlayer">
        <v-btn
          text
          small
          class="d-md-flex"
          @click="openDrawer"
        >
          <v-icon>
            mdi-bell
          </v-icon>
        </v-btn>
      </template>
    
      <v-divider
        inset
        vertical
        class="d-none d-md-flex"
      />
      
      <v-menu
        open-on-hover
        offset-y
        bottom
        transition="slide-y-transition"
        class="mx-4"
      >
        <template #activator="{ on: menu }">
          <v-btn
            text
            small
            v-on="{ ...menu }"
          >
            <v-icon class="d-md-none">
              mdi-account-circle-outline
            </v-icon>
            <span class="d-none d-md-flex">
              {{ userDisplayName }}
            </span>
            <v-icon right>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="goToProfilePage()">
            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Perfil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="userIsPlayer"
            class="d-md-none"
            @click="openDrawer"
          >
            <v-list-item-action>
              <v-icon>
                mdi-bell
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Notificações
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="d-md-none"
            @click="toggleDarkMode()"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{
                  $vuetify.theme.dark ? "Modo claro" : "Modo escuro"
                }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon
                right
                dense
              >
                {{
                  $vuetify.theme.dark
                    ? "mdi-white-balance-sunny"
                    : "mdi-moon-waxing-crescent"
                }}
              </v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider class="my-2 d-md-none" />
          <v-list-item @click="logout()">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import AuthMixin from "@/mixins/Auth.vue";
import DialogNotificationPlayer from "@/components/Dialog/DialogNotificationPlayer/DialogNotificationPlayer.vue";

export default {
	name: "AppBar",

	components: {
		DialogNotificationPlayer,
	},

	mixins: [AuthMixin],

	data() {
		return this.initialState();
	},

  created() {
    this.fetchMyNotifications();
  },

	computed: {
		...mapGetters({
			sidebarStatus: "UI/sidebarStatus",
			themeDarkMode: "UI/themeDarkMode",
			userAuthenticated: "auth/userAuthenticated",
			userDisplayName: "auth/userDisplayName",
			userGroup: "auth/userGroup",
			userTrackerId: "auth/userTrackerId",
			// currencies: 'currencies/currencies',
		}),

		userDisplayLetter() {
			return this.userDisplayName !== undefined
				? this.userDisplayName[0].toUpperCase()
				: "";
		},

		toggleDarkModePhrase() {
			let word = this.themeDarkMode == true ? "Acender" : "Apagar";
			return word + " as luzes";
		},
	},

	// watch: {
	//   userAuthenticated: {
	//     handler() {
	//       if (this.userAuthenticated) {
	//         this.fetchCurrencies();
	//       };
	//     },
	//     immediate: true,
	//   },
	// },

	methods: {
		initialState() {
			return {
        dialogues: {
          confirm: false,
			}}
		},
		toggleSidebar() {
			this.$store.dispatch("UI/toggleSidebar");
		},

		toggleDarkMode() {
			this.$store.dispatch("UI/toggleThemeDarkMode");
		},

		openDrawer() {
      if(this.userIsPlayer){
        this.$root.$emit("toggle-drawer-notification-user", true);
      }
		},
    openDialog() {
      this.dialogues.confirm = true;
    },

    fetchMyNotifications() {
      if (this.userAuthenticated && this.userIsPlayer) {
        this.$store.dispatch("notifications/getMyNotifications");
      }
    },

		logout() {
			this.$store.dispatch("auth/logout");
			this.$router.push({
				path: "/login/",
				query: {
					logout: "true",
				},
			});
		},

		goToProfilePage() {
			this.$router.push({
				path: "/players/" + this.userTrackerId + "/",
			});
		},

		getRouteParamId() {
			let output = "";
			if (
				this.$route &&
				(this.$route.name == "Ficheiro #" ||
					this.$route.name == "Operação #" ||
					this.$route.text == "Evento #" ||
					this.$route.text == "Torneio #" ||
					this.$route.text == "Equipa #" ||
					this.$route.text == "Jogador #")
			) {
				output = this.$route.params.id;
			}
			return output;
		},

		// fetchCurrencies() {
		//   if (this._.isEmpty(this.currencies)) {
		//     this.$store.dispatch('currencies/get');
		//   }
		// },

		// getCurrencyRate(item) {
		//   return this.currencies[item] ? this.currencies[item].rate : 0;
		// }
	},
};
</script>

<style>
</style>